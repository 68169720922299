import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
  GenderMale,
  GenderFemale,
  GraphUp,
  Repeat,
} from "react-bootstrap-icons";

export default function BoyGirlForm(props) {
  return (
    <Form id="boyGirlForm">
      <Row className="mb-2 mt-4 p-0">
        <Col>
          {props.boyGirlSwitch ? (
            <GenderFemale
              onClick={props.changeSwitch}
              color="pink"
              size="32"
              className="ms-2 me-2"
            />
          ) : (
            <GenderMale
              onClick={props.changeSwitch}
              color="royalblue"
              size="24"
            />
          )}
        </Col>
        <Col>
          <GraphUp
            size="24"
            onClick={props.displayCharts}
            className="ms-2 me-2"
          />
        </Col>
        <Col>
          <Repeat size="24" onClick={props.resetForm} className="ms-2 me-e" />
        </Col>
      </Row>
      <Row>
        <InputGroup
          size="sm"
          className="ms-0 me-0 mt-1 mb-2 pt-0 ps-1 pe-1 pb-0"
        >
          <InputGroup.Text>Data nasterii</InputGroup.Text>
          <Form.Control
            type="date"
            aria-label="Data nasterii"
            value={props.birthDate}
            onChange={props.handleBirthDate}
            size="sm"
          />
        </InputGroup>
      </Row>
    </Form>
  );
}
