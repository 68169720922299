import React from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export default function MeasureForm(props) {

  return (
      <Form onSubmit={props.handleSubmit} className="form-inline">
        <Row>
          <InputGroup className="mb-1">
            <Col xs={4}>
              <InputGroup.Text id="x">
                {props.graphData.xTitle}:
              </InputGroup.Text>
            </Col>
            <Col xs={4}>
              <InputGroup.Text id="y">
                {props.graphData.yTitle}:
              </InputGroup.Text>
            </Col>
            <Col xs={2}>
              <InputGroup.Text id="z">Perc:</InputGroup.Text>
            </Col>
          </InputGroup>
        </Row>
        {props.measures.map((val, idx) => {
          let measureId = `measure-${idx}`,
            yId = `y-${idx}`;
          //console.log(measures[0],measureId)
          return (
            <Row key={idx + 1}>
              <div className="col-8 me-0 pe-0">
                {" "}
                <Form.Group className="form-horizontal">
                  <InputGroup className="mb-1">
                    <FormControl
                      type="number"
                      step="any"
                      name={measureId}
                      id={measureId}
                      data-id={idx}
                      className="x form-horizontal"
                      value={props.measures[idx].x}
                      onChange={props.handleChange}
                    />
                    <FormControl
                      type="number"
                      step="any"
                      name={yId}
                      id={yId}
                      data-id={idx}
                      className="y form-horizontal"
                      value={props.measures[idx].y}
                      onChange={props.handleChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="col-2 m-0 p-0">
                <InputGroup className="mb-1">
                  <InputGroup.Text>
                    {
                      //this.state.p[idx].measures[idx].x
                    }
                    {props.getPercentiles({
                      [props.measures[idx].x]: props.measures[idx].y,
                    })}
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="col-1 ms-0 ps-0">
                <Button
                  variant="success"
                  data-id={idx}
                  onClick={props.newMeasure}
                >
                  +
                </Button>
              </div>
              {
                //only show the remove button when there are more that one measure in the dataset`
              }
              <div className="col-1 ms-0 ps-0">
                <Button
                  variant="danger"
                  data-id={idx}
                  onClick={props.removeMeasure}
                  disabled={props.measures.length > 1 ? 0 : 1}
                >
                  -
                </Button>
              </div>
            </Row>
          );
        })}
      </Form>
  )
}
