import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner';
import Chart from './Chart'
export default function ChartModal(props) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [spinnerShow, setSpinnerShow] = useState(true);

  const handleShow = () => {
    setShow(true);
  };


  useEffect(() => {
    setSpinnerShow(false);
  }, [show]);

  return (
    <>
      {spinnerShow ? <Spinner animation="grow" /> : null}
      <Card
        key={props.graphName}
        bg={props.graphName.includes("boy") ? "primary" : "danger"}
        className="mb-2"
        text="white"
        onClick={() => handleShow(props.graphName)}
        style={{ witdth: '25rem' }}
      >
        <Card.Body>
          <Card.Title>{props.graphChartTitle}</Card.Title>
          <Card.Text>Realizeaza grafic pentru{props.graphChartTitle}</Card.Text>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{props.graphChartTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body><Chart chartName={props.graphName} zTable={props.zTable} />
        </Modal.Body>
      </Modal>
    </>
  )
}
