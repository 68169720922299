import React, { Component } from 'react'
//import MenuItems from './NavbarItems'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Navbar, Nav } from 'react-bootstrap'


export default class ChartNavbar extends Component {
    constructor(props) {
        super(props)
        this.updateChartType = this.updateChartType.bind(this)
    }

    updateChartType(e) {
       // console.log('functionClicked')
        this.props.updateChartData(e.data.id)
    }

    render() {
        return (
                <Navbar bg="light" variant="light" expand="lg" sticky="top">
                    <Navbar.Brand href="#home">Growth Chart</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/Home">Home</Nav.Link>
                            <Nav.Link href="/ChartV2">Grafice</Nav.Link>
                            <Nav.Link href="/DrugSearch">FDA Drug Search</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/Admin">Admin</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
        )
    }
}
