import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import GraphAdminCard from "./GraphAdminCard";

export default function Admin() {
  let [graphNames, setgraphNames] = useState([]);
  const [spinnerShow, setSpinnerShow] = useState(true);
  //const backend = "http://localhost:3001";
  //get a list of graph in DB

  function handleError(error) {
    alert(error);
  }

  useEffect(() => {
    async function getGraphNames() {
      await axios
        .get("/graph/names", {
          //baseURL: backend,
          debounce: 200,
          debounceImmediate: true,
          isReady: true,
        })
        .then((response) => {
          setgraphNames(response.data);
        })
        .catch((error) => handleError(error));

      setSpinnerShow(false);
    }
    getGraphNames().catch(console.error);
  }, []);
  return (
    <Container>
      <Row>
        {spinnerShow ? <Spinner animation="grow" variant="primary" /> : null}
        {graphNames
          .filter((graphName) => graphName.name.toLowerCase().includes("boy"))
          .map((graph) => (
            <Col xs={12} md={6} lg={4} xl={2}>
              <GraphAdminCard
                graphName={graph.name}
                graphChartTitle={graph.chartTitle}
              />
            </Col>
          ))}
      </Row>
      <Row>
        {spinnerShow ? <Spinner animation="grow" variant="primary" /> : null}
        {graphNames
          .filter((graphName) => graphName.name.toLowerCase().includes("girl"))
          .map((graph) => (
            <Col xs={12} md={6} lg={4} xl={2}>
              <GraphAdminCard
                graphName={graph.name}
                graphChartTitle={graph.chartTitle}
              />
            </Col>
          ))}
      </Row>
    </Container>
  );
}
