//components

import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Home from '../Home/Home'
import About from '../About/About';
import Contact from '../Contact/Contact';
import Notfound from '../Notfound';
import DrugSearch from '../openFda/DrugSearch'
import Admin from '../Admin/Admin.jsx';
import Chart from '../Chart/ChartCards.jsx'
import ChartV2 from '../ChartV2/ChartV2';
//import Navbar from '../Header/Navbar/Navbar'
export default class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path="/Home" element={<Home />} />
                    <Route exact path="/" element={<ChartV2 />} />
                    <Route exact path="/DrugSearch" element={<DrugSearch />} />
                    <Route exact path="/Contact-us" element={<Contact />} />
                    <Route exact path="/About-us" element={<About />} />
                    <Route exact path="/Admin" element={<Admin />} />
                    <Route exact path="/Chart" element={<Chart />}/>
                    <Route exact path="/ChartV2" element={<ChartV2 />}/>
                    <Route exact path="*" element={<Notfound />} />
                </Routes>
            </BrowserRouter>

        )
    }

}