import React, { useState, useEffect, useMemo } from "react";
import MeasureForm from "./MeasureForm";
import Container from "react-bootstrap/Container";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import BoyGirlForm from "./BoyGirlForm";
import OffCanvasChart from "./OffCanvasChart";

export default function ChartV2() {
  let [boyGirlSwitch, setBoyGirlSwitch] = useState(false);
  let [birthDate, setBirthDate] = useState("");
  let [measures, setMeasures] = useState([
    {
      measureDate: "",
      weight: "",
      length: "",
      circumference: "",
      month: "",
      weightPercentile: "",
      lengthPercentile: "",
      circumferencePercentile: "",
      weightToolTip: [],
      lengthToolTip: [],
      circumferenceToolTip: [],
    },
  ]);
  let [zTable, setZTable] = useState();
  let [childGraphData, setChildGraphData] = useState();
  let [teenGraphData, setTeenGraphData] = useState();
  let [alertShow, setAlertShow] = useState(false);
  let [alertMessage, setAllertMessage] = useState();
  let [showCharts, setShowCharts] = useState(false);
  let [chartWidth, setChartWidth] = useState(100);
  let [maxAge, setMaxAge] = useState();
  let [minAge, setMinAge] = useState();
  let [child, setChild] = useState(true);
  let [teen, setTeen] = useState(false);

  function calculateZscore(measurement, measurementIndex, chartType, age) {
    const X = measurement;
    const graphData = age <= 24 ? childGraphData : teenGraphData;
    const M = graphData
      .find((g) => g.name === chartType)
      .percentiles.find((p) => p.percentileName === "M").values[
      measurementIndex
    ];

    const L = graphData
      .find((g) => g.name === chartType)
      .percentiles.find((p) => p.percentileName === "L").values[
      measurementIndex
    ];
    const S = graphData
      .find((g) => g.name === chartType)
      .percentiles.find((p) => p.percentileName === "S").values[
      measurementIndex
    ];

    //    console.log("XMLS", X, M, L, S, measurement, measurementIndex);
    // console.log('explodeZscore', explodeZscore(-1.83))

    let Z = L !== 0 ? (Math.pow(X / M, L) - 1) / (L * S) : Math.LN10(X / M) / S;
    //         console.log('rawZ', Z)
    //if  z is outside -4.99 and 4.99 range return the range limit
    Z = Z <= 4.99 && Z >= -4.99 ? Z : Z > 4.99 ? 4.99 : -4.99;
    // console.log("Z", Z);
    return Z.toFixed(2);

    /*
          
          ((X/M)**L) – 1
  Z = ————————-, L≠0
  LS
  
  or
  
  Z = ln(X/M)/S ,L=0
          */
  }
  function explodeZscore(zscore) {
    const first = (
      parseFloat((zscore * 10).toString().split(".")[0]) / 10
    ).toFixed(2);
    const second = (
      parseFloat((zscore * 10).toFixed(2).toString().split(".")[1]) / 1000
    ).toFixed(2);

    // console.log('zscore in explode', zscore)
    return [first, second];
  }
  function getChartType(field, x) {
    let chartType;

    switch (field) {
      case "weight":
        if (x > 24) {
          chartType = `teen${!boyGirlSwitch ? "Boy" : "Girl"}WeightForAge`;
        } else {
          chartType = `${!boyGirlSwitch ? "boy" : "girl"}WeightForAge`;
        }
        break;
      case "length":
        if (x > 24) {
          chartType = `teen${!boyGirlSwitch ? "Boy" : "Girl"}StatureForAge`;
        } else {
          chartType = `${!boyGirlSwitch ? "boy" : "girl"}LengthForAge`;
        }
        break;
      case "circumference":
        chartType = `${!boyGirlSwitch ? "boy" : "girl"}HeadCircumference`;
        break;
      default:
        return "";
    }
    return chartType;
  }
  function getPercentiles(x, y, field) {
    let chartType;
    //console.log('getpercentile', field)
    //set chratype to get the correct chart type or exit calculation when field is neither of the values below
    //console.log(x, y, field, "getpercentileInput");
    switch (field) {
      case "weight":
        if (x > 24) {
          chartType = `teen${!boyGirlSwitch ? "Boy" : "Girl"}WeightForAge`;
        } else {
          chartType = `${!boyGirlSwitch ? "boy" : "girl"}WeightForAge`;
        }
        break;
      case "length":
        if (x > 24) {
          chartType = `teen${!boyGirlSwitch ? "Boy" : "Girl"}StatureForAge`;
        } else {
          chartType = `${!boyGirlSwitch ? "boy" : "girl"}LengthForAge`;
        }
        break;
      case "circumference":
        chartType = `${!boyGirlSwitch ? "boy" : "girl"}HeadCircumference`;
        break;
      default:
        return "";
    }
    //console.log(chartType);

    let graphData = {};
    if (x < 24) {
      graphData = childGraphData;
    } else {
      graphData = teenGraphData;
    }
    //console.log(graphData);
    //console.log(childGraphData, 'childGraphData in get percentiles')
    if (!graphData || !graphData.find((g) => g.name === chartType)) {
      return;
    }
    let labels = graphData.find((g) => g.name === chartType).labels;

    let upperLim = labels.indexOf(labels.find((el) => el >= x));
    let Zscore = calculateZscore(
      y,
      labels.indexOf(labels[upperLim < 0 ? 0 : upperLim]),
      chartType,
      x
    );
    let explodedZscore = explodeZscore(Zscore);
    let zPosition = zTable
      .find((p) => p.name === "Z")
      .values.indexOf(parseFloat(explodedZscore[1]));

    let percentile = zTable.find((p) => p.name === explodedZscore[0]).values[
      zPosition
    ];
    /*console.log(
          "zscore",
          Zscore,
          "explodedZScore",
          explodedZscore,
          "zPosition",
          zPosition
      );*/
    return percentile;
  }

  function calculateMonth(birthD, measureD) {
    //console.log('calculateMonth', birthD, measureD)
    if (!birthD || !measureD) {
      return "";
    }
    const birthDate = new Date(birthD);
    const measureDate = new Date(measureD);

    if (birthDate > measureDate) {
      let error = {};
      error.message = "Data nasterii trebuie sa fie inainte de data masurarii";
      handleError(error);
      return "";
    }
    if (alertShow) {
      setAlertShow(false);
    }
    const dateDiff = (measureDate - birthDate) / (1000 * 3600 * 24);
    return dateDiff / 30.5 <= 24
      ? parseInt(Math.round(dateDiff / 30.5))
      : parseFloat(Math.round(dateDiff / 30.5 + 0.5) - 0.5);
  }

  function handleChange(e) {
    let ind = e.target.dataset.i;
    let field = e.target.dataset.field;
    setMeasures((prevMeasures) => {
      let newMeasures = [...prevMeasures];
      newMeasures[ind][field] = e.target.value;

      if (field === "measureDate") return newMeasures;

      newMeasures[ind][`${field}Percentile`] = getPercentiles(
        measures[ind].month,
        e.target.value,
        field
      );

      if (field === "measureDate") return newMeasures;
      const P5 = getPercentileValue("P5", field, ind);
      const P50 = getPercentileValue("P50", field, ind);
      const P95 = getPercentileValue("P95", field, ind);
      newMeasures[ind][`${field}ToolTip`] = { P5: P5, P50: P50, P95: P95 };
      return newMeasures;
    });
  }

  function displayAge(mons) {
    if (mons === undefined) return "";
    if (mons === 0) return "0 luni";
    if (mons === 1) return "o luna";
    if (mons === 13) return "un an si o luna";
    if (mons > 1 && mons / 12 < 1) return `${mons} luni`;
    if (mons / 12 === 1) return "1 an";
    if (mons / 12 > 1 && mons / 12 < 2) return `1 an si ${mons - 12} luni`;
    if (Math.floor(mons / 12) > 1 && mons - Math.floor(mons / 12) * 12 === 0)
      return `${mons / 12} ani`;
    if (Math.floor(mons / 12) > 2 && mons - Math.floor(mons / 12) * 12 === 1)
      return `${Math.floor(mons / 12)} ani si o luna`;
    if (mons / 12 > 2)
      return `${Math.floor(mons / 12)} ani si ${
        mons - Math.floor(mons / 12) * 12
      } luni`;
  }

  function getPercentileValue(percentile, field, index) {
    //head circumference for teen does not exists, we need to guard against it.
    if (field === "circumference" && measures[index].month > 24) return "";

    const graphData =
      measures[index].month <= 24 ? childGraphData : teenGraphData;

    const labels = graphData.find(
      (g) => g.name === getChartType(field, measures[index].month)
    ).labels;

    const upperLim = labels.indexOf(
      labels.find((el) => el >= measures[index].month)
    );

    const measurementIndex = labels.indexOf(
      labels[upperLim < 0 ? 0 : upperLim]
    );

    const percValue = graphData
      .find((g) => g.name === getChartType(field, measures[index].month))
      .percentiles.find((p) => p.percentileName === percentile).values[
      measurementIndex
    ];
    return percValue.toFixed(2);
  }

  function handleBlur(e) {
    let ind = e.target.dataset.i;
    let field = e.target.dataset.field;
    let newMeasures = Object.assign(measures);

    if (field === "measureDate" && birthDate) {
      newMeasures[ind].month = calculateMonth(birthDate, e.target.value);
      //recalculate all percentiles
      newMeasures[ind].weightPercentile = getPercentiles(
        measures[ind].month,
        newMeasures[ind].weight,
        "weight"
      );
      newMeasures[ind].lengthPercentile = getPercentiles(
        measures[ind].month,
        newMeasures[ind].length,
        "length"
      );
      newMeasures[ind].circumferencePercentile = getPercentiles(
        measures[ind].month,
        newMeasures[ind].circumference,
        "circumference"
      );
    } else {
      newMeasures[ind][`${field}Percentile`] = getPercentiles(
        measures[ind].month,
        e.target.value,
        field
      );
    }

    let ages = [];

    ages = newMeasures.map((measure) => parseInt(measure.month));
    let maxAge = Math.max(...ages);
    let minAge = Math.min(...ages);
    setMeasures(newMeasures);
    setMinAge(minAge);
    setMaxAge(maxAge);
  }

  useMemo(() => {
    if (minAge < 24 && !child) {
      setChild(true);
    }

    if (minAge >= 24 && child) {
      setChild(false);
    }

    if (maxAge > 24 && !teen) {
      setTeen(true);
    }
    if (maxAge <= 24 && teen) {
      setTeen(false);
    }
  }, [minAge, maxAge]);

  function addMeasurement(e) {
    //console.log(e)
    let ind = e.target.dataset.i + 1;
    setMeasures((prevMeasures) => {
      let newMeasures = [...prevMeasures];
      newMeasures.splice(ind, 0, {
        measureDate: "",
        weight: "",
        length: "",
        circumference: "",
        month: "",
        weightPercentile: "",
        lengthPercentile: "",
        circumferencePercentile: "",
      });
      return newMeasures;
    });
  }

  function removeMeasurement(e) {
    let ind = e.target.dataset.i;

    setMeasures((prevMeasures) => {
      let newMeasures = [...prevMeasures];
      if (newMeasures.length < 2) return;
      newMeasures.splice(ind, 1);
      return newMeasures;
    });
  }

  function changeSwitch(e) {
    setBoyGirlSwitch((prevBoyGirlSwitch) => !prevBoyGirlSwitch);
  }
  function handleError(error) {
    setAlertShow(true);
    setAllertMessage(error.message);
  }

  function handleBirthDate(e) {
    setBirthDate(e.target.value);
    //recalculate month for forms
    setMeasures((prevMeasures) => {
      let newMeasures = [...prevMeasures];
      newMeasures.forEach((measure, index) => {
        newMeasures[index].month = calculateMonth(
          e.target.value,
          measure.measureDate
        );
      });
      return newMeasures;
    });
  }
  function resetForm() {
    setMeasures([
      {
        measureDate: "",
        weight: "",
        length: "",
        circumference: "",
        month: "",
        weightPercentile: "",
        lengthPercentile: "",
        circumferencePercentile: "",
      },
    ]);
  }

  function hideCharts() {
    setShowCharts(false);
  }

  function displayCharts() {
    setShowCharts((prevShowChart) => !prevShowChart);
  }

  function changeZoom(e) {
    //console.log(e.target.value)
    setChartWidth(e.target.value);
  }

  useEffect(() => {
    async function getZTable() {
      await axios
        .get("/zTable", {
          // baseURL: "http://localhost:3001",
          debounce: 200,
          debounceImmediate: true,
          isReady: true,
        })
        .then((response) => {
          setZTable(response.data);
        })
        .catch((error) => handleError(error));
    }
    getZTable();
  }, []);

  useEffect(() => {
    //console.log(boyGirlSwitch, "boyGirlSwitch use effect");

    async function getChildGraphDatabyGender(boyGirlSwitch, age) {
      await axios
        .get(`/graph/bygender/${!boyGirlSwitch ? "male" : "female"}/${age}`, {
          // baseURL: "http://localhost:3001",
          debounce: 200,
          debounceImmediate: true,
          isReady: true,
        })
        .then((response) => {
          if (age === "child") {
            setChildGraphData(response.data);
          } else {
            setTeenGraphData(response.data);
          }
        })
        .catch((error) => handleError(error));
    }
    if (child && teen) {
      getChildGraphDatabyGender(boyGirlSwitch, "child");
      getChildGraphDatabyGender(boyGirlSwitch, "teen");
    } else if (child && !teen) {
      getChildGraphDatabyGender(boyGirlSwitch, "child");
    } else {
      getChildGraphDatabyGender(boyGirlSwitch, "teen");
    }
  }, [boyGirlSwitch, child, teen]);

  useEffect(() => {
    function recalculatePercentiles() {
      setMeasures((prevMeasures) => {
        if (prevMeasures) {
          let newMeasures = [...prevMeasures];
          newMeasures.forEach((measure, ind) => {
            newMeasures[ind].weightPercentile = measure.weight
              ? getPercentiles(
                  newMeasures[ind].month,
                  newMeasures[ind].weight,
                  "weight"
                )
              : "";
            newMeasures[ind].lengthPercentile = measure.length
              ? getPercentiles(
                  newMeasures[ind].month,
                  newMeasures[ind].length,
                  "length"
                )
              : "";
            newMeasures[ind].circumferencePercentile = measure.circumference
              ? getPercentiles(
                  newMeasures[ind].month,
                  newMeasures[ind].circumference,
                  "circumference"
                )
              : "";
          });
          return newMeasures;
        }
      });
    }
    recalculatePercentiles();
    //
    //update data for tooltip
  }, [childGraphData, teenGraphData]);

  return (
    <Container>
      {!!alertShow ? (
        <Alert variant="danger" onClose={() => setAlertShow(false)} dismissible>
          {alertMessage}
        </Alert>
      ) : null}

      <BoyGirlForm
        boyGirlSwitch={boyGirlSwitch}
        changeSwitch={changeSwitch}
        displayCharts={displayCharts}
        resetForm={resetForm}
        birthDate={birthDate}
        handleBirthDate={handleBirthDate}
      />
      <MeasureForm
        handleChange={handleChange}
        handleBlur={handleBlur}
        measures={measures}
        addMeasurement={addMeasurement}
        removeMeasurement={removeMeasurement}
        birthDate={birthDate}
        teen={teen}
        displayAge={displayAge}
      />
      {showCharts ? (
        <OffCanvasChart
          showCharts={showCharts}
          hideCharts={hideCharts}
          chartWidth={chartWidth}
          changeZoom={changeZoom}
          graphData={childGraphData}
          measures={measures}
          birthDate={birthDate}
          boyGirlSwitch={boyGirlSwitch}
          teenGraphData={teenGraphData}
          child={child}
          teen={teen}
          displayAge={displayAge}
        />
      ) : null}
    </Container>
  );
}
