import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

export default function MeasureForm(props) {
  let [showWeighTooltip, setShowWeightTooltip] = useState(false);
  let [showLengthTooltip, setShowLengthTooltip] = useState(false);
  let [showCircumferenceTooltip, setShowCircumferenceTooltip] = useState(false);

  let weighttarget = useRef(null);
  let lengthTarget = useRef(null);
  let circumferenceTarget = useRef(null);

  return (
    <Row>
      {!!props.measures.length
        ? props.measures.map((measure, i) => (
            <Form size="sm" className="p-0 m-0" key={i}>
              <Form.Group
                as={Row}
                controlId={`${i}-measureDate`}
                className="p-0 m-0"
              >
                <Form.Label className="m-0 ps-1" column="sm" xs={4}>
                  Data:{" "}
                </Form.Label>
                <Col xs={4} className="p-0 m-0">
                  <Form.Control
                    type="date"
                    aria-label="Data Masuratorii"
                    aria-describedby="measureDateLabel"
                    value={measure.measureDate}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    data-i={i}
                    data-field="measureDate"
                    size="sm"
                    className="m-0 p-0"
                  />
                </Col>
                <Form.Label className="m-0 pt-1" column="sm" xs={4}>
                  {`Varsta: ${props.displayAge(measure.month)}`}
                </Form.Label>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId={`${i}-weight`}
                className="ps-0 m-0"
              >
                <Form.Label column="sm" xs={4} className="ps-1 m-0">
                  Greutate(KG):{" "}
                </Form.Label>
                <Col xs={4} className="ps-1 pt-0 pe-0 pb-0 m-0">
                  <Form.Control
                    type="number"
                    aria-label="Greutatea "
                    aria-describedby="measuredWeightLabel"
                    value={measure.weight}
                    onChange={props.handleChange}
                    data-i={i}
                    data-field="weight"
                    size="sm"
                    className="p-0 m-0"
                    ref={weighttarget}
                    onFocus={() => setShowWeightTooltip(!showWeighTooltip)}
                    onBlur={() => setShowWeightTooltip(!showWeighTooltip)}
                    disabled={!(props.birthDate && measure.measureDate)}
                    key={`weightinput-${i}`}
                  />
                  <Overlay
                    target={weighttarget.current}
                    show={showWeighTooltip}
                    placement="left"
                    tooltip={measure.weightToolTip}
                  >
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        {!measure.weightToolTip
                          ? null
                          : Object.entries(measure.weightToolTip).map(
                              (perc) => (
                                <p key={perc[0]} className="m-0 p-0">
                                  {perc[0]}: {perc[1]}
                                </p>
                              )
                            )}
                      </Tooltip>
                    )}
                  </Overlay>
                </Col>
                <Form.Label
                  className="m-0 pt-1"
                  column="sm"
                  xs={4}
                >{`Percentila: ${measure.weightPercentile}`}</Form.Label>
              </Form.Group>

              <Form.Group as={Row} controlId="length" className="p-0 m-0">
                <Form.Label
                  column="sm"
                  xs={4}
                  className="ps-1 pt-0 pe-0 pb-0 m-0"
                >
                  {measure.month > 24 ? "Statura (cm):" : "Lungime (cm):"}
                </Form.Label>
                <Col xs={4} className="ps-1 pt-0 pe-0 pb-0 m-0">
                  <Form.Control
                    type="number"
                    aria-label="Lungimea "
                    aria-describedby="measuredLengthLabel"
                    value={measure.length}
                    onChange={props.handleChange}
                    data-i={i}
                    data-field="length"
                    size="sm"
                    className="m-0 p-0"
                    disabled={!(props.birthDate && measure.measureDate)}
                    ref={lengthTarget}
                    onFocus={() => setShowLengthTooltip(!showLengthTooltip)}
                    onBlur={() => setShowLengthTooltip(!showLengthTooltip)}
                  />
                </Col>
                <Overlay
                  target={lengthTarget.current}
                  show={showLengthTooltip}
                  placement="left"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      {measure.lengthToolTip
                        ? Object.entries(measure.lengthToolTip).map((perc) => (
                            <p key={perc[0]} className="m-0 p-0">
                              {perc[0]}: {perc[1]}
                            </p>
                          ))
                        : null}
                    </Tooltip>
                  )}
                </Overlay>
                <Form.Label
                  className="m-0 pt-1"
                  column="sm"
                  xs={4}
                >{`Percentila: ${measure.lengthPercentile}`}</Form.Label>
              </Form.Group>
              {measure.month > 24 && measure.length && measure.weight ? (
                <Form.Group as={Row} controlId="bmi" className="p-0 m-0">
                  <Form.Label
                    column="sm"
                    xs={4}
                    className="ps-1 pt-0 pe-0 pb-0 m-0"
                  >
                    BMI:{" "}
                  </Form.Label>
                  <Col xs={4} className="ps-1 pt-0 pe-0 pb-0 m-0">
                    <Form.Control
                      type="number"
                      aria-label="BMI"
                      aria-describedby="measuredBMILabel"
                      value={(
                        (measure.weight / measure.length / measure.length) *
                        10000
                      ).toFixed(2)}
                      data-i={i}
                      data-field="length"
                      size="sm"
                      className="m-0 p-0"
                      readOnly={true}
                    />
                  </Col>
                  <Form.Label
                    className="m-0 pt-1"
                    column="sm"
                    xs={4}
                  ></Form.Label>
                </Form.Group>
              ) : null}
              {measure.month <= 24 ? (
                <Form.Group
                  as={Row}
                  controlId="circumference"
                  className="p-0 m-0"
                >
                  <Form.Label
                    column="sm"
                    xs={4}
                    className="ps-1 pt-0 pe-0 pb-0 m-0"
                  >
                    Circumferinta(cm):{" "}
                  </Form.Label>
                  <Col xs={4} className="ps-1 pt-0 pe-0 pb-0 m-0">
                    <Form.Control
                      type="number"
                      aria-label="Circumferinta "
                      aria-describedby="measuredCircumferenceLabel"
                      value={measure.circumference}
                      onChange={props.handleChange}
                      data-i={i}
                      data-field="circumference"
                      size="sm"
                      className="m-0 p-0"
                      disabled={!(props.birthDate && measure.measureDate)}
                      ref={circumferenceTarget}
                      onFocus={() =>
                        setShowCircumferenceTooltip(!showCircumferenceTooltip)
                      }
                      onBlur={() =>
                        setShowCircumferenceTooltip(!showCircumferenceTooltip)
                      }
                    />
                  </Col>
                  <Overlay
                    target={circumferenceTarget.current}
                    show={showCircumferenceTooltip}
                    placement="left"
                    data-html={true}
                  >
                    {(props) => (
                      <Tooltip id="overlay-example" {...props} data-html={true}>
                        {measure.circumferenceToolTip
                          ? Object.entries(measure.circumferenceToolTip).map(
                              (perc) => (
                                <p key={perc[0]} className="m-0 p-0">
                                  {perc[0]}: {perc[1]}
                                </p>
                              )
                            )
                          : null}
                      </Tooltip>
                    )}
                  </Overlay>
                  <Form.Label
                    className="m-0 pt-1"
                    column="sm"
                    xs={4}
                  >{`Percentila: ${measure.circumferencePercentile}`}</Form.Label>
                </Form.Group>
              ) : null}
              <Row>
                <ButtonGroup className="ms-0 mt-0 me-0 mb-3 ps-3 pe-3 pt-1">
                  <Button
                    variant="danger"
                    onClick={props.removeMeasurement}
                    disabled={props.measures.length > 1 ? false : true}
                    data-i={i}
                    size="sm"
                  >
                    -
                  </Button>
                  <Button
                    variant="success"
                    onClick={props.addMeasurement}
                    data-i={i}
                    size="sm"
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Row>
            </Form>
          ))
        : null}
    </Row>
  );
}
