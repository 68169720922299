//components

import React, { Component } from 'react';
import Router from './Router';
//import Navbar from '../Header/Navbar/Navbar'
export default class MainApp extends Component {

    componentDidMount() {
        document.addEventListener('contextmenu', (e) => {

          //console.log('context menu')
        //  e.preventDefault();
        });
      };

      
    render() {
        return (
           <Router />
        
        )
    }

}