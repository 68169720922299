import React from "react";
import { Toast } from "react-bootstrap";

export default function ToastNotification(props) {
  return (
    <Toast onClose = { ()=> props.handleToastShow()} show={props.show} delay={props.delay} autohide>
      <Toast.Header>{props.toastHeader}</Toast.Header>
      <Toast.Body>{props.toastBody}</Toast.Body>
    </Toast>
  );
}
