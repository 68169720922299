import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PercentilesForm from "./PercentilesForm";
import ToastNotification from "./ToastNotification";
import Spinner from "react-bootstrap/Spinner";

export default function GraphAdminCard(props) {
  const [show, setShow] = useState(false);
  const [graphData, setGraphData] = useState();
  const [toastShow, setToastShow] = useState(false);
  const handleClose = () => setShow(false);
  const [toastHeader, setToastHeader] = useState("");
  const [toastBody, setToastBody] = useState("");
  const [spinnerShow, setSpinnerShow] = useState(true);

  //const backend = "http://localhost:3001";

  function handleError(error) {
    alert(error);
  }

  const handleShow = () => {
    setShow(true);
  };

  const handleToastShow = () => {
    setToastShow(!toastShow);
  };

  function handleChange(e) {
    setGraphData((prevGraphData) => {
      let newGraphData = {};
      Object.assign(newGraphData, prevGraphData);
      //      console.log(newGraphData);
      const minusPos = e.target.id.indexOf("-") + 1;
      const percName = e.target.id.substring(minusPos);

      const percIndex = prevGraphData.percentiles.findIndex(
        (o) => o.percentileName === percName
      );
      const percProperty = e.target.id.substring(0, minusPos - 1);
      newGraphData.percentiles[percIndex][percProperty] =
        percProperty !== "checked"
          ? e.target.value
          : !prevGraphData.percentiles[percIndex][percProperty];
      //console.log(newGraphData);
      return newGraphData;
    });
  }

  async function handleSubmit() {
    await axios
      .patch(`/graph/${graphData.name}`, graphData, {
        //  baseURL: backend,
        debounce: 200,
        debounceImmediate: true,
        isReady: true,
      })
      .then((response) => {
        setToastHeader("Preferences saved");
        setToastBody(`Preferences for graph: ${graphData.name} were saved`);
      })
      .catch((error) => handleError(error));
    setShow(false);
    setToastShow(true);
  }

  useEffect(() => {
    setSpinnerShow(false);
  }, [show]);

  return (
    <>
      {spinnerShow ? <Spinner animation="grow" /> : null}
      <Card
        key={props.graphName}
        bg={
          props.graphName.toLowerCase().includes("boy") ? "primary" : "danger"
        }
        className="mb-2"
        text="white"
        onClick={() => handleShow(props.graphName)}
        style={{ witdth: "25rem" }}
      >
        <Card.Body>
          <Card.Title>{props.graphChartTitle}</Card.Title>
          <Card.Text>Edit settings for {props.graphChartTitle}</Card.Text>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} fullscreen={"md-down"}>
        <Modal.Header closeButton>
          <Modal.Title>{props.graphChartTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PercentilesForm
            graphData={graphData}
            handleChange={handleChange}
            graphName={props.graphName}
            setGraphData={setGraphData}
            handleError={handleError}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondady" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastNotification
        show={toastShow}
        toastHeader={toastHeader}
        toastBody={toastBody}
        delay={3000}
        handleToastShow={handleToastShow}
      />
    </>
  );
}
