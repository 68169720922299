import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

export default function DrugSearchForm(props) {
  let [search, setSearch] = useState([
    {
      displayName: "Nume Generic:",
      name: "generic_name",
      key: "openfda.generic_name",
      value: "",
    },
    {
      displayName: "Producator:",
      name: "manufacturer_name",
      key: "openfda.manufacturer_name",
      value: "",
    },
    {
      displayName: "Nume comercial:",
      name: "brand_name",
      key: "openfda.brand_name",
      value: "",
    },
    {
      displayName: "Nume substanta:",
      name: "substance_name",
      key: "openfda.substance_name",
      value: "",
    },
    {
      displayName: "Ingredient activ",
      name: "active_ingredient",
      key: "active_ingredient",
      value: "",
    },
  ]);

  function handleChange(e) {
    setSearch((prevSearch) => {
      let newSearch = [...prevSearch];
      console.log(e.target.name);
      let searchedField = newSearch.find((f) => f.name === e.target.name);
      console.log("searchedField", searchedField);

      let searchedFieldIndex = newSearch.findIndex(
        (f) => f.name === e.target.name
      );
      console.log(searchedFieldIndex);

      searchedField.value = e.target.value;
      search[searchedFieldIndex] = searchedField;
      return newSearch;
    });
  }

  function searchDrug() {
    let queryString = "";

    search.forEach(
      (s) =>
        (queryString +=
          s.value !== ""
            ? queryString === ""
              ? (queryString += `${s.key}:${s.value}`)
              : `+AND+${s.key}:${s.value}`
            : "")
    );

    console.log(encodeURI(queryString));
    const baseUrl = "https://api.fda.gov/drug/label.json";
    let parameters = {
      search: encodeURI(queryString),
      limit: 30,
      sort: "effective_time:desc",
    };

    console.log(
      typeof Object.entries(Object.assign({}, parameters))
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    );
    let axiosOpt = {
      params: parameters,
      paramsSerializer: {
        serialize: function (params) {
          return Object.entries(Object.assign({}, params))
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
            .toString();
        },
      },
      debounce: 200,
      debounceImmediate: true,
      isReady: true,
    };
    axios
      .get(baseUrl, axiosOpt)
      .then((response) => {
        console.log("results");
        props.handleResults(response.data);
      })
      .catch((error) => {
        console.error(error);
        props.handleError(error);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    searchDrug();
  }

  return (
    <Form onSubmit={handleSubmit}>
      {console.log(search)}
      <Row>
        <Col xs={12} sm={6} xl={3} key="search1">
          {search.map((searchField) => (
            <InputGroup className="mb-1" key={searchField.name}>
              <InputGroup.Text id={`label-${searchField.name}`}>
                {searchField.displayName}
              </InputGroup.Text>
              <Form.Control
                name={searchField.name}
                id={`in-${searchField.name}`}
                data-id={searchField.key}
                value={searchField.value}
                onChange={handleChange}
              />
            </InputGroup>
          ))}
        </Col>
      </Row>
      <Row>
        <Button variant="success" data-id="search" onClick={handleSubmit}>
          {" "}
          Seach
        </Button>
      </Row>
    </Form>
  );
}
