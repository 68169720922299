import React  from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from "chartjs-plugin-zoom";
import { Line } from 'react-chartjs-2'
import { isMobile } from 'react-device-detect'
import Row from 'react-bootstrap/Row'


let plugins = [zoomPlugin,
    //  ChartDataLabels
]
Chart.register(plugins

); // REGISTER PLUGIN
function LineChart(props) {


    let myLine = {
        data: props.lineData,
        label: "Masuratoare",
        borderColor: "#000000",
        backgroundColor: randomColor(),
        fill: false,
        parsing: false,
        borderWidth: 4,
    }

    let chartData = []

    props.basedata.percentiles.filter(percentile => percentile.checked === true).forEach(percentile => {

        chartData.push({
            data: percentile.values,
            label: percentile.percentileName,
            borderColor: percentile.color,
            backgroundColor: percentile.color,
            fill: false,
            borderWidth: 2,
            tension: 0.4,
        })
    })

    chartData = [...chartData, myLine]

    let Alldata = {
        labels: props.basedata.displayLabels,
        datasets: chartData
    }


    let chartOptions = {
        scales: {
            x: {
                grid: {
                    borderColor: 'black'
                    ,
                },
                title: {
                    display: true,
                    text: props.basedata.xTitle,
                    //   type: 'logarithmic'
                },
                scaleLabel: {
                    display: true,
                    labelString: 'X'
                }

            },
            y: {
                title: {
                    display: true,
                    text: props.basedata.yTitle,

                }, scaleLabel: {
                    display: true,
                    labelString: 'Y'
                }


            }
        },
        maintainAspectRatio: true
        , aspectRatio: isMobile ? 1 : 2
        , datasets: {
            line: {
                borderWidth: 1,
                borderColor: "#000000",

            }
        },
        responsive: true,
        plugins: {
            zoom: {
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: false
                    },
                    mode: 'xy',
                    // speed: 19,
                }, pan: {
                    enabled: false,
                    mode: "xy",
                    speed: 50
                }
            },
            title: {
                display: true,
                text: props.basedata.chartTitle
            },
            ChartDataLabels: {
                display: false,
            },

            legend: {
                //display: !isMobile,
                position: 'top',

            }
        }
    }

    return (
        <Row style={{ height: "150vh", width: "150vh" }}>
            <Line data={Alldata} options={chartOptions} />
        </Row>
    )


}


function randomColor() {
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * (16 - 1 + 1)).toString(16)
    }

    return color
}




export default LineChart;