import React, { useState, useEffect } from 'react'
import LineChart from './LineChart'
import MeasureForm from './MeasureForm'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


export default function Chart(props) {
  const zTable = props.zTable

  let [measures, setMeasures] = useState()


  const backend = "http://localhost:3001";
  let [graphData, setGraphData] = useState()
  let [spinnerShow, setSpinnerShow] = useState(true)

  function handleError(error) {
    alert(error);
  }


  function handleChange(e) {
    //console.log('classname',e.target.className);
    if (["x", "y"].includes(e.target.className.substring(0, 1))) {
      let changedMeasures = [...measures];
      changedMeasures[e.target.dataset.id][e.target.className.substring(0, 1)] =
        e.target.value;
      setMeasures(changedMeasures);
    } else {
      setMeasures({ [e.target.name]: e.target.value });
    }
  }


  function newMeasure(e) {
    //console.log(e.target)
    setMeasures((prevMeasures) => {
      let measures = [...prevMeasures];
      let id = parseInt(e.target.dataset.id);
      // insert the new measure
      measures.splice(parseInt(e.target.dataset.id) + 1, 0, {
        x: parseInt(measures[id].x) + 1,
        y: measures[id].y,
      });
      return measures;
    })
    //update the graph data in the parent component
  }


  function handleSubmit(event) {
    event.preventDefault();
  }
  function getPercentiles(measure) {
    //let indexedPercentiles = {}
    let labels = graphData.labels
    // console.log(measure);

    let x = Object.keys(measure)[0];
    let y = Object.values(measure)[0];

    let upperLim = labels.indexOf(labels.find((el) => el >= x));
    let Zscore = calculateZscore(y, labels.indexOf(labels[upperLim < 0 ? 0 : upperLim]));
    let explodedZscore = explodeZscore(Zscore);
    let zPosition = zTable.find(p => p.name === 'Z').values.indexOf(parseFloat(explodedZscore[1]));

    let percentile = zTable.find(p => p.name === explodedZscore[0]).values[zPosition];
    /*console.log(
      "zscore",
      Zscore,
      "explodedZScore",
      explodedZscore,
      "zPosition",
      zPosition
    )*/;
    return percentile;
  }


  function removeMeasure(e) {
    setMeasures((prevMeasures) => {
      let measures = [...prevMeasures];
      // remove measure

      measures.splice(parseInt(e.target.dataset.id), 1);
      //TODO update following nodes

      // measures.map((element, index) => index >= id ? element.x += 1 : element.x)
      /* console.log(measures)*/
      return measures;
    });
  }



  function calculateZscore(measurement, measurementIndex) {
    const X = measurement;
    console.log(graphData.percentiles)
    const M = graphData.percentiles.find(p => p.percentileName === 'M').values[measurementIndex];
    const L = graphData.percentiles.find(p => p.percentileName === 'L').values[measurementIndex];
    const S = graphData.percentiles.find(p => p.percentileName === 'S').values[measurementIndex];

    console.log("XMLS", X, M, L, S, measurement, measurementIndex);
    // console.log('explodeZscore', explodeZscore(-1.83))

    let Z = L !== 0 ? (Math.pow(X / M, L) - 1) / (L * S) : Math.LN10(X / M) / S;
    /// console.log('rawZ', Z)
    //if  z is outside -4.99 and 4.99 range return the range limit
    Z = Z <= 4.99 && Z >= -4.99 ? Z : Z > 4.99 ? 4.99 : -4.99;
    // console.log("Z", Z);
    return Z.toFixed(2);

    /*
        
        ((X/M)**L) – 1
Z = ————————-, L≠0
LS

or

Z = ln(X/M)/S ,L=0
        */
  }

  function explodeZscore(zscore) {
    const first = (
      parseFloat((zscore * 10).toString().split(".")[0]) / 10
    ).toFixed(2);
    const second = (
      parseFloat((zscore * 10).toFixed(2).toString().split(".")[1]) / 1000
    ).toFixed(2);

    // console.log('zscore in explode', zscore)
    return [first, second];
  }

  useEffect(() => {
    async function getGraphData(graphName) {
      await axios
        .get(`/graph/${graphName}`, {
        //  baseURL: backend,
          debounce: 200,
          debounceImmediate: true,
          isReady: true,
        })
        .then(response => {
          setGraphData(response.data)
          setMeasures([{
            x: response.data.labels[0], y: response.data.percentiles.find(
              p => p.percentileName === 'M').values[0]
          }])
        })
        .catch(error => handleError(error))
    }
    getGraphData(props.chartName)
    setSpinnerShow(false)
  }, [props.chartName])


  return (<Row><Col xs={12} lg={6} xxl={2}>
    {spinnerShow ? <Spinner animation="grow" variant='primary' /> : null}
    {!!graphData ? <MeasureForm
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleError={handleError}
      newMeasure={newMeasure}
      removeMeasure={removeMeasure}
      getPercentiles={getPercentiles}
      graphData={graphData}
      measures={measures}
    /> : null}</Col>
    <Col xs={12} lg={6} xxl={10}>
      {!!measures ? <LineChart
        lineData={measures}
        basedata={graphData}
        CheckedPercentiles={["P3"]}
      />  : null}</Col>
  </Row>
  )
}
