import React, { useState } from "react";
import DrugSearchForm from "./DrugSearchForm";
import { Container, Row, Accordion, Card, ListGroup } from "react-bootstrap";

const DrugSearch = () => {
  let [drug, setDrug] = useState();
  let [qResult, setQResult] = useState({});
  let [qError, setQError] = useState();

  function getResult(result) {
    setQResult(result);
  }
  function getError(error) {
    setQError(error);
  }

  return (
    <Container>
      <Row>
        <p>Cautare de medicamente dupa numele comercial</p>
        <DrugSearchForm handleResults={getResult} handleError={getError} />
      </Row>

      <Row>
        <p>
          {Object.keys(qResult).length > 0
            ? ` Rezultate: ${qResult.meta.results.total}, se arata primele: ${qResult.meta.results.limit} rezultate`
            : ""}
        </p>
        {Object.keys(qResult).length > 0
          ? qResult.results.map((r) => (
              <Card>
                <Card.Header>
                  {`${r.openfda.brand_name} - ${r.openfda.manufacturer_name}`}
                </Card.Header>
                <Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item
                      dangerouslySetInnerHTML={{
                        __html: `<p>Dozaj</p><p>${
                          r.dosage_and_administration_table
                            ? r.dosage_and_administration_table
                            : r.dosage_and_administration
                        }</p>`,
                      }}
                    ></ListGroup.Item>
                    <ListGroup.Item
                      dangerouslySetInnerHTML={{
                        __html: `<p>Contracindicatii</p><p>${
                          r.contraindications_table
                            ? r.contraindications_table
                            : r.contraindications
                        }</p>`,
                      }}
                    ></ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            ))
          : null}
      </Row>
    </Container>
  );
};

export default DrugSearch;
