import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import MainApp from './components/Main/Main.jsx';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import { Container, Row} from 'react-bootstrap';
import { StrictMode } from 'react';
export default function App() {


  return (
  <StrictMode>
  <Container fluid>
    <Row>
      <Header />
    </Row>
    <Row>
      <MainApp />
    </Row>
    <Row>
      <Footer />
    </Row>
  </Container >
  </StrictMode>
  );
}