import React from 'react'
import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import ChartModal from './ChartModal.jsx'

export default function ChartCards() {
    let [graphNames, setgraphNames] = useState([])
    let [zTable, setZTable] = useState([])
    const [spinnerShow, setSpinnerShow] = useState(true)

  const backend = "http://localhost:3001";
    function handleError(error) {
        console.log(error)
    }
    useEffect(() => {
        async function getGraphNames() {
            await axios
                .get("/graph/names", {
                 //   baseURL: backend,
                    debounce: 200,
                    debounceImmediate: true,
                    isReady: true,
                })
                .then((response) => {
                    setgraphNames(response.data);
                })
                .catch((error) => handleError(error));

        }


        async function getZTable() {
            await axios
                .get("/zTable", {
                   // baseURL: backend,
                    debounce: 200,
                    debounceImmediate: true,
                    isReady: true,
                })
                .then((response) => {
                    setZTable(response.data);
                })
                .catch((error) => handleError(error));
        }
        getGraphNames()
        getZTable()
        setSpinnerShow(false);
    }, [])

    return (
        <Container>
            <Row>
                {spinnerShow ? (
                    <Spinner animation="grow" variant="primary" />
                ) : null}
                {graphNames
                    .filter((graphName) => graphName.name.includes("boy"))
                    .map((graph) => (
                        <Col xs={12} md={6} lg={4} xl={2}>
                            <ChartModal
                                graphName={graph.name}
                                graphChartTitle={graph.chartTitle}
                                zTable = {zTable}
                            />
                        </Col>
                    ))}
            </Row>
            <Row>
                {spinnerShow ? (
                    <Spinner animation="grow" variant="primary" />
                ) : null}
                {graphNames
                    .filter((graphName) => graphName.name.includes("girl"))
                    .map((graph) => (
                        <Col xs={12} md={6} lg={4} xl={2}>
                            <ChartModal
                                graphName={graph.name}
                                graphChartTitle={graph.chartTitle}
                                zTable = {zTable}
                            />
                        </Col>
                    ))}
            </Row>
        </Container>
    )
}
