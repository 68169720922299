import React from "react";
import Form from "react-bootstrap/Form";
import LineChart from "./LineChart";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";

export default function OffCanvasChart(props) {
  return (
    <Offcanvas
      show={props.showCharts}
      onHide={props.hideCharts}
      responsive="md"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Grafice</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Col>
          <Row>
            <Form.Group>
              <Form.Label>Zoom:</Form.Label>
              <Form.Range
                id="zoom"
                value={props.chartWidth}
                onChange={props.changeZoom}
                min={30}
                max={280}
              />
            </Form.Group>
          </Row>
          {props.child ? (
            <Row
              style={{
                width: `${props.chartWidth}vh`,
                height: `${props.chartWidth}vh`,
              }}
            >
              {!!(
                props.graphData &&
                props.measures &&
                props.graphData.find(
                  (g) =>
                    g.name ===
                    `${!props.boyGirlSwitch ? "boy" : "girl"}WeightForAge`
                )
              ) ? (
                <LineChart
                  lineDataRaw={props.measures
                    .filter((m) => m.month <= 24)
                    .filter((m) => m.weight !== undefined)}
                  lineData={props.measures
                    .filter((m) => m.month <= 24)
                    .filter((m) => m.weight !== undefined)
                    .map((measure) => ({
                      x: measure.month,
                      y: parseFloat(measure.weight),
                    }))}
                  basedata={props.graphData.find(
                    (g) =>
                      g.name ===
                      `${!props.boyGirlSwitch ? "boy" : "girl"}WeightForAge`
                  )}
                  chartWidth={props.chartWidth}
                  birthDate={props.birthDate}
                  age="child"
                  displayAge={props.displayAge}
                />
              ) : (
                <div>Loading weight chart</div>
              )}
            </Row>
          ) : null}
          {props.child ? (
            <Row
              style={{
                width: `${props.chartWidth}vh`,
                height: `${props.chartWidth}vh`,
              }}
            >
              {!!(
                props.graphData &&
                props.measures &&
                props.graphData.find(
                  (g) =>
                    g.name ===
                    `${!props.boyGirlSwitch ? "boy" : "girl"}LengthForAge`
                )
              ) ? (
                <LineChart
                  lineDataRaw={props.measures
                    .filter((m) => m.month <= 24)
                    .filter((m) => m.length !== undefined)}
                  lineData={props.measures
                    .filter((m) => m.month <= 24)
                    .filter((m) => m.length !== undefined)
                    .map((measure) => ({
                      x: measure.month,
                      y: parseFloat(measure.length),
                    }))}
                  basedata={props.graphData.find(
                    (g) =>
                      g.name ===
                      `${!props.boyGirlSwitch ? "boy" : "girl"}LengthForAge`
                  )}
                  chartWidth={props.chartWidth}
                  birthDate={props.birthDate}
                  age="child"
                  displayAge={props.displayAge}
                />
              ) : (
                <div>Loading Length chart</div>
              )}
            </Row>
          ) : null}
          {props.child ? (
            <Row
              style={{
                width: `${props.chartWidth}vh`,
                height: `${props.chartWidth}vh`,
              }}
            >
              {!!(
                props.graphData &&
                props.measures &&
                props.graphData.find(
                  (g) =>
                    g.name ===
                    `${!props.boyGirlSwitch ? "boy" : "girl"}HeadCircumference`
                )
              ) ? (
                <LineChart
                  lineDataRaw={props.measures
                    .filter((m) => m.month <= 24)
                    .filter((m) => m.circumference !== undefined)}
                  lineData={props.measures
                    .filter((m) => m.month <= 24)
                    .filter((m) => m.circumference !== undefined)
                    .map((measure) => ({
                      x: measure.month,
                      y: parseFloat(measure.circumference),
                    }))}
                  basedata={props.graphData.find(
                    (g) =>
                      g.name ===
                      `${
                        !props.boyGirlSwitch ? "boy" : "girl"
                      }HeadCircumference`
                  )}
                  chartWidth={props.chartWidth}
                  birthDate={props.birthDate}
                  age="child"
                  displayAge={props.displayAge}
                />
              ) : (
                <div>Loading HeadCircumference chart</div>
              )}
            </Row>
          ) : null}

          {props.teen ? (
            <Row
              style={{
                width: `${props.chartWidth}vh`,
                height: `${props.chartWidth}vh`,
              }}
            >
              {!!(
                props.teenGraphData &&
                props.measures &&
                props.teenGraphData.find(
                  (g) =>
                    g.name ===
                    `teen${!props.boyGirlSwitch ? "Boy" : "Girl"}WeightForAge`
                )
              ) ? (
                <LineChart
                  lineDataRaw={props.measures
                    .filter((m) => m.month > 24)
                    .filter((m) => m.weight !== undefined)}
                  lineData={props.measures
                    .filter((m) => m.month > 24)
                    .filter((m) => m.weight !== undefined)
                    .map((measure) => ({
                      x: parseFloat(measure.month),
                      y: parseFloat(measure.weight),
                    }))}
                  basedata={props.teenGraphData.find(
                    (g) =>
                      g.name ===
                      `teen${!props.boyGirlSwitch ? "Boy" : "Girl"}WeightForAge`
                  )}
                  chartWidth={props.chartWidth}
                  birthDate={props.birthDate}
                  age="teen"
                  displayAge={props.displayAge}
                />
              ) : (
                <div>Loading teen chart</div>
              )}
            </Row>
          ) : null}
          {props.teen ? (
            <Row
              style={{
                width: `${props.chartWidth}vh`,
                height: `${props.chartWidth}vh`,
              }}
            >
              {!!(
                props.teenGraphData &&
                props.measures &&
                props.teenGraphData.find(
                  (g) =>
                    g.name ===
                    `teen${!props.boyGirlSwitch ? "Boy" : "Girl"}StatureForAge`
                )
              ) ? (
                <LineChart
                  lineDataRaw={props.measures
                    .filter((m) => m.month > 24)
                    .filter((m) => m.length !== undefined)}
                  lineData={props.measures
                    .filter((m) => m.month > 24)
                    .filter((m) => m.length !== undefined)
                    .map((measure) => ({
                      x: parseFloat(measure.month),
                      y: parseFloat(measure.length),
                    }))}
                  basedata={props.teenGraphData.find(
                    (g) =>
                      g.name ===
                      `teen${
                        !props.boyGirlSwitch ? "Boy" : "Girl"
                      }StatureForAge`
                  )}
                  chartWidth={props.chartWidth}
                  birthDate={props.birthDate}
                  age="teen"
                  displayAge={props.displayAge}
                />
              ) : (
                <div>Loading teen chart</div>
              )}
            </Row>
          ) : null}
        </Col>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
