import React, { Component } from 'react'
import Ale from '../static/images/Ale.jpg'
import Music from '../static/sound/sera_porque_te_amo_.ogg'
import Card from 'react-bootstrap/Card'
import ReactAudioPlayer from 'react-audio-player';

export default class Home extends Component {
    render() {

        return (



            <div className='row'>
                <div className='col-xl-2 col-xs-2 col-sm-2 col-md-2'>
                    <ReactAudioPlayer
                        src={Music}
                        controls
                    />
                </div>
                <div className='col-xl-4 col-xs-4 col-sm-4 col-md-4'>
                    <Card className='card text-white bg-info mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                        <Card.Img variant="top" src={Ale} />
                        <Card.Body>
                            <Card.Title>Buna Ale,</Card.Title>
                            <Card.Text>
                                Sunt deja 2 ani de cand, in aceasta zi, mi-ai scris sa imi ceri pararea pentru o aplicatie. Timpul zboara, piticul ceasului munceste de zor si nici
                                nu am realizat cand au trecut acesti 2 ani minunati. Iti multumesc mult pe aceasta cale pentru ca asa am ajuns sa te cunosc pe tine, om minunat si suflet drag!
                                <br /> Sunt 2 ani in care am vorbit cate in luna si in stele,ne-am impartasit sentimente, vise, ganduri... practic viata noastra.
                                <br />Sunt 2 ani in care ne-am dezvoltat si am crescut fiecare, dar si impreuna!
                                <br />Sunt 2 ani care m-au facut, chiar daca ar fi posibil, sa nu ma intorc in trecut sa schimb ceva pentru ca imi place unde suntem si unde cred ca ne indreptam!
                                <br />Ma simt onorat ca te-am cunoscut, ca am invatat atat de multe de la tine si privilegiat ca ai ales sa imparti cu mine, ganduri, idei, sentimente, casa... viata ta cu mine
                            </Card.Text>
                            <Card.Footer >Te Iubesc! Esti cea mai tare!</Card.Footer>
                            <Card.Link href="/About-us">Despre acest proiect!</Card.Link>

                        </Card.Body>
                    </Card>
                </div>




                <div className='col-xl-4 col-xs-4 col-sm-4 col-md-4'></div>
            </div >
        )
    }
}
