import React, { useEffect, useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
export default function PercentilesForm(props) {
  const [spinnerShow, setSpinnerShow] = useState(true);
  useEffect(() => {
    //const backend = "http://localhost:3001";
    async function getGraphData(graphName) {
      await axios
        .get(`/graph/${graphName}`, {
         // baseURL: backend,
          debounce: 200,
          debounceImmediate: true,
          isReady: true,
        })
        .then((response) => {
          props.setGraphData(response.data);
        })
        .catch((error) => props.handleError(error));
    }
    getGraphData(props.graphName);
    setSpinnerShow(false);
  }, [props.graphName]);
  return (
    <>
      {" "}
      {spinnerShow ? <Spinner animation="grow" /> : null}
      <Form
        onSubmit={props.handleSumbit}
        className="form-inline"
        id={props.graphName}
        // onBlur={props.handleSumbit}
      >
        {!!props.graphData
          ? props.graphData.percentiles
              .filter((percentile) => percentile.percentileName.includes("P"))
              .map((percentile) => (
                <Row key={percentile.percentileName}>
                  <Col xs={2} className="m-0 p-0">
                    <InputGroup className="form-inline m-0 p-0">
                      <Form.Control
                        type="text"
                        disabled
                        id={`Name-${percentile.percentileName}`}
                        name={`Name-${percentile.percentileName} `}
                        value={percentile.percentileName}
                        onChange={props.handleChange}
                        key={`Name-${percentile.percentileName}`}
                        data-formname={props.graphData.name}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={7} className="m-0 p-0">
                    <InputGroup className="form-inline m-0 p-0">
                      <InputGroup.Text>Culoare</InputGroup.Text>
                      <Form.Control
                        type="color"
                        disabled={
                          percentile.percentileName.includes("P") ? 0 : 1
                        }
                        id={`color-${percentile.percentileName}`}
                        name={`color-${percentile.percentileName} `}
                        value={percentile.color}
                        onChange={props.handleChange}
                        key={`color-${percentile.percentileName}`}
                        data-formname={props.graphData.name}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={2} className="m-0 p-0">
                    <InputGroup className="form-inline m-0 p-0">
                      <Form.Check
                        disabled={
                          percentile.percentileName.includes("P") ? 0 : 1
                        }
                        type="switch"
                        id={`checked-${percentile.percentileName}`}
                        name={`checked-${percentile.percentileName} `}
                        checked={percentile.checked}
                        onChange={props.handleChange}
                        key={`switch-${percentile.checked}`}
                        data-formname={props.graphData.name}
                        label={percentile.checked ? "Activ":"Inactiv"}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              ))
          : null}
      </Form>
    </>
  );
}
