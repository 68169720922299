import React, { useRef } from "react";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
let plugins = [zoomPlugin, ChartDataLabels];
Chart.register(plugins);

function LineChart(props) {
  const chartRef = useRef();
  let chartData = [];

  let displayLabels = [];
  // let birthDate = new Date(props.birthDate);
  let monthData = props.lineData.map((m) => m.x.toString());
  let labels =
    props.birthDate && props.basedata
      ? props.basedata.displayLabels.filter((dl) =>
          props.age === "child"
            ? dl % 6 === 0 || monthData.includes(dl)
            : Math.floor(dl) % 12 === 0 || monthData.includes(dl)
        )
      : [...props.basedata.displayLabels];
  displayLabels =
    props.birthDate && props.basedata
      ? labels.map((value) => props.displayAge(parseFloat(value)))
      : labels;

  /*
          ${new Date(
              new Date(birthDate).setMonth(
                birthDate.getMonth() + parseInt(value)
              )
            ).toLocaleDateString("en-GB", {
              month: "numeric",
              year: "numeric",
            })}
        )
  */
  let labelIndex = labels.map((l) => props.basedata.displayLabels.indexOf(l));
  //revrite line data to match the filtered x values
  let newLineData = props.lineData.map((l) => ({
    x: labelIndex.indexOf(props.basedata.displayLabels.indexOf(l.x.toString())),
    y: l.y,
  }));

  let myLine = {
    data: newLineData,
    label: "Masuratoare",
    borderColor: "#000000",
    backgroundColor: randomColor(),
    fill: false,
    parsing: false,
    borderWidth: 4,
  };
  if (props.basedata) {
    props.basedata.percentiles
      .filter((percentile) => percentile.checked === true)
      .forEach((percentile) => {
        chartData.push({
          data: percentile.values.filter((p) =>
            labelIndex.includes(percentile.values.indexOf(p))
          ),
          label: percentile.percentileName,
          borderColor: percentile.color,
          backgroundColor: percentile.color,
          fill: false,
          borderWidth: 2,
          tension: 0.4,
        });
      });
  }
  chartData = [...chartData, myLine];
  let Alldata = {
    labels: displayLabels,
    datasets: chartData,
  };

  let chartOptions = {
    scales: {
      x: {
        grid: {
          borderColor: "black",
        },
        title: {
          display: true,
          text: props.basedata ? props.basedata.xTitle : "",
          //   type: 'logarithmic'
        },
        scaleLabel: {
          display: true,
          labelString: "X",
        },
      },
      y: {
        title: {
          display: true,
          text: props.basedata ? props.basedata.yTitle : "",
        },
        scaleLabel: {
          display: true,
          labelString: "Y",
        },
      },
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    datasets: {
      line: {
        borderWidth: 1,
        borderColor: "#000000",
      },
    },
    responsive: true,
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false,
          },
          mode: "xy",
          // speed: 19,
        },
        pan: {
          enabled: false,
          mode: "xy",
          speed: 50,
        },
      },
      title: {
        display: true,
        text: props.basedata ? props.basedata.chartTitle : "",
      },
      datalabels: {
        display: false,
        borderRadius: 4,
        //color: 'white',
        font: {
          size: 18,
        },
        formatter: Math.round,
        padding: 6,
      },
      legend: {
        //display: !isMobile,
        position: "bottom",
      },
    },
  };

  return (
    <>
      <Line data={Alldata} options={chartOptions} ref={chartRef} />
      {/*<Row>
        {chartRef.current ? (
          <Button
            varian="primary"
            size="sm"
            href={chartRef.current.toBase64Image()}
            download="chart.png"
            target="_blank"
            rel="noreferrer"
          >
            Download
          </Button>
        ) : null}
      </Row>
    */}
    </>
  );
}

function randomColor() {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * (16 - 1 + 1)).toString(16);
  }

  return color;
}

export default LineChart;
